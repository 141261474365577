<template>
    <div class="navbar">
        <el-tooltip class="item" effect="dark" content="左移至首页" placement="top">
            <div class="left el-icon-d-arrow-left" @click="toLeft"></div>
        </el-tooltip>
        <div class="main" ref="navMain">
            <div class="nav-body" ref="navBody" :style="{left: `${bodyLeft.left}px`}" @click="hideTagContextmenu">
                <el-tag
                        v-for="(tag, index) in visitedViews"
                        :key="tag.name"
                        type="success"
                        :closable="!tag.affix"
                        @close="hideTagContextmenu() && closeTag(tag, index)"
                        @click="toSelected(tag)"
                        @contextmenu.native="contextmenuListener($event, tag, index)"
                        :effect="isActive(tag) ? 'dark' : 'plain'">
                    <i v-if="tag.icon" slot="default" :class="tag.icon"></i>
                    <span :ref="tag.path">{{tag.title}}</span>
                </el-tag>
            </div>
            <reference-changeable-popover v-model="tagContextmenuVisible"
                                          :reference="contextmenuSelectedTag.domElement">
                <ul class="contextmenu" @click="hideTagContextmenu">
                    <li @click="refreshTag">刷新标签页</li>
                    <li v-if="contextmenuSelectedTag.value && !contextmenuSelectedTag.value.affix"
                        @click="closeTag(contextmenuSelectedTag.value, contextmenuSelectedTag.index)">关闭标签页
                    </li>
                    <li @click="closeOther">关闭其他标签页</li>
                    <li @click="closeAll">关闭所有标签页</li>
                </ul>
            </reference-changeable-popover>
        </div>
        <el-tooltip class="item" effect="dark" content="右移至最后一个标签页" placement="top">
            <div class="right el-icon-d-arrow-right" @click="toRight"></div>
        </el-tooltip>
    </div>
</template>

<script>
    import path from 'path'
    import {mapActions, mapGetters} from "vuex";
    import RouterDispatcher from './components/RouteDispatcher'
    import ReferenceChangeablePopover from '@/components/x/popover/reference-changeable-popover'

    export default {
        name: 'NavBar',
        mixins: [RouterDispatcher],
        components: {ReferenceChangeablePopover},
        data() {
            return {
                bodyLeft: {
                    left: 0
                },
                affixTags: [],
                contextmenuSelectedTag: {
                    value: null,
                    index: 0,
                    domElement: null
                },
                tagContextmenuVisible: false
            }
        },
        computed: {
            ...mapGetters({routes: "permission_routes", visitedViews: 'tags'}),
            currentTag() {
                const {hidden, fullPath, meta = {}, name} = this.$route;
                return {name, path: fullPath, fullPath, hidden, ...meta};
            }
        },
        watch: {
            $route() {
                this.addTagsIfAbsent();
                this.toCurrTag()
            }
        },
        mounted() {
            this.initAffixTags();
            this.addTagsIfAbsent();
            this.$bus.$on("closeTagByCurrentRoute", this.closeTagByCurrentRoute);
        },
        methods: {
            ...mapActions({
                removeVisitedView: 'viewTag/removeVisitedView',
                addVisitedView: 'viewTag/addVisitedView'
            }),
            isActive(route) {
                return route.path === this.$route.fullPath
            },
            closeTagByCurrentRoute(route) {
                let index = -1;
                for (let i = 0; i < this.visitedViews.length; i++) {
                    if (this.visitedViews[i].name === route.name) {
                        index = i;
                        break;
                    }
                }
                if (index > -1) {
                    this.closeTag({...route, path: route.fullPath}, index);
                }
            },
            toClosestOrLastView(visitedViews = [], currentViewIndex) {
                //优先切换至右侧tab
                let rightView;
                if (currentViewIndex && currentViewIndex > 0 && visitedViews.length > currentViewIndex) {
                    rightView = visitedViews.slice(currentViewIndex, currentViewIndex + 1);
                }
                //无则切换至最后一个tab
                const latestView = (rightView && rightView[0]) || visitedViews.slice(-1)[0];
                // or to reload home page
                this.goto(latestView, '/');
            },
            closeTag(route, index) {
                if (route.affix) return;

                this.removeVisitedView(route);
                // 如果删除的是当前页，则进行跳转
                if (route.path === this.$route.fullPath) {
                    this.toClosestOrLastView(this.visitedViews, index);
                }
            },
            toSelected(route) {
                route.path === this.$route.fullPath || this.goto(route)
            },
            initAffixTags() {
                const affixTags = this.affixTags = this.filterAffixTags(this.routes);
                for (const tag of affixTags) {
                    // Must have tag name
                    if (tag.name) {
                        this.addVisitedView(tag);
                    }
                }
            },
            refreshTag() {
                let {fullPath} = this.contextmenuSelectedTag.value;
                this.$store.dispatch('viewTag/removeCachedView', this.currentTag).then((data) => {
                    this.$nextTick(() => {
                        this.$router.replace({path: '/redirect' + fullPath});
                    });
                });
            },
            addTagsIfAbsent() {
                const {name, isTag} = this.currentTag;
                if (name && isTag) {
                    this.addVisitedView(this.currentTag);
                }
            },
            filterAffixTags(routes, basePath = '/') {
                let tags = [];
                routes.forEach(route => {
                    if (route.meta && route.meta.affix) {
                        const tagPath = path.resolve(basePath, route.path);
                        tags.push({
                            fullPath: tagPath,
                            path: tagPath,
                            name: route.name,
                            ...route.meta
                        })
                    }
                    if (route.children) {
                        const affixTagsOfChildren = this.filterAffixTags(route.children, route.path);
                        if (affixTagsOfChildren.length) {
                            tags = [...tags, ...affixTagsOfChildren]
                        }
                    }
                });
                return tags;
            },
            toCurrTag() {
                let refArray;
                if ((refArray = this.$refs['' + this.$route.path]) && refArray.length > 0) {
                    var dom = refArray[0].parentElement;
                    var currLeft = dom.offsetLeft;
                    var currWidth = dom.clientWidth;
                    var navWidth = this.$refs.navMain.clientWidth;
                    var left = navWidth - currLeft - currWidth - 4;
                    this.bodyLeft.left = Math.min(0, left);
                }
            },
            toLeft() {
                this.bodyLeft.left = 0;
            },
            toRight() {
                this.bodyLeft.left = Math.min(0, this.$refs.navMain.clientWidth - this.$refs.navBody.clientWidth);
            },
            closeOther() {
                let tags = this.visitedViews;
                const {path: selectedPath} = this.contextmenuSelectedTag.value;
                let closeableTags = tags.filter(item => !item.affix && item.path !== selectedPath);

                closeableTags.forEach(item => this.removeVisitedView(item));
                this.toSelected(this.contextmenuSelectedTag.value);
                this.toLeft();
            },
            closeAll() {
                let tags = this.visitedViews;
                let closeableTags = tags.filter(item => !item.affix);
                closeableTags.forEach(item => this.removeVisitedView(item));

                this.toClosestOrLastView(this.visitedViews);
                this.toLeft();
            },
            contextmenuListener(event, tag, index) {
                this.contextmenuSelectedTag = {value: tag, index, domElement: event.target};
                this.tagContextmenuVisible = true;
                event.preventDefault();
                event.stopPropagation();
            },
            hideTagContextmenu() {
                this.tagContextmenuVisible = false;
                return true;
            }
        }
    };
</script>
